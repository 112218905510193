import React from 'react';
import { CarouselPhotoContentSection as CarouselPhotoContentSectionDS } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

export const CarouselPhotoContentSection = ({ items, title }) => {
    return (
        <CarouselPhotoContentSectionDS
            title={title}
            items={items.map((item) => ({
                ...item,
                image: getSrc(item?.image?.file),
            }))}
            isLight={true}
        />
    );
};

export const query = graphql`
    fragment DatoCmsCarouselPhotoContentSection on DatoCmsCarouselPhotoContentSection {
        title
        items {
            pretitle
            title
            text
            image {
                alt
                file {
                    publicURL
                    extension
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`;

export default CarouselPhotoContentSection;
