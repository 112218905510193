import React from 'react';

const PREFIX = 'DatoCms';

export default function Section({ __typename: type, ...props }) {
    let SectionComponent = () => <div> -- Section {type} not found -- </div>;

    switch (type) {
        case `${PREFIX}TextSection`:
            SectionComponent = require('./components/TextSection').default;
            break;
        case `${PREFIX}HtmlSection`:
            SectionComponent = require('./components/HTMLSection').default;
            break;
        case `${PREFIX}CardsSection`:
            SectionComponent = require('./components/CardsSection').default;
            break;
        case `${PREFIX}CarouselPhotoContentSection`:
            SectionComponent =
                require('./components/CarouselPhotoContentSection').default;
            break;
        case `${PREFIX}TitleSection`:
            SectionComponent = require('./components/TitleSection').default;
            break;
        case `${PREFIX}SectionsTitleSection`:
            SectionComponent =
                require('./components/SectionsTitleSection').default;
            break;
        case `${PREFIX}ItemsDescriptionSection`:
            SectionComponent =
                require('./components/ItemsDescriptionSection').default;
            break;
        case `${PREFIX}PictureSection`:
            SectionComponent = require('./components/PictureSection').default;
            break;
        case `${PREFIX}AnchorSection`:
            SectionComponent = require('./components/AnchorSection').default;
            break;
        case `${PREFIX}CardsYearSection`:
            SectionComponent = require('./components/CardsYearSection').default;
            break;
        default:
            break;
    }

    return <SectionComponent {...props} />;
}
