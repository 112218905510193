import React from 'react';
import { graphql } from 'gatsby';

export default function AnchorSection({ anchor }) {
    return <div id={anchor} style={{ transform: 'translateY(-100px)' }}></div>; // Menu height 80px + 20px air
}

export const query = graphql`
    fragment DatoCmsAnchorSection on DatoCmsAnchorSection {
        anchor
    }
`;
