import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import { Layout } from '@solublestudio/gatsby-theme-soluble-source';

import Section from '../sections';

export default function Page({ data, location, pageContext }) {
    const locale = pageContext?.locale;

    const sections = useMemo(() => data?.datoCmsPage?.sections ?? [], [data]);

    return (
        <Layout navbarFixed={true} navbarTransparent={true}>
            {sections.map((section, i) => (
                <Section
                    key={i}
                    order={i + 1}
                    isLast={i + 1 === sections.length}
                    pageSlug={data?.datoCmsPage?.slug}
                    location={location}
                    locale={locale}
                    {...section}
                />
            ))}
        </Layout>
    );
}

export const query = graphql`
    fragment Button on DatoCmsButton {
        label
        href
        target
        file {
            url
        }
    }

    query Page($id: String, $language: String) {
        datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
            title
            sections {
                ... on DatoCmsCarouselPhotoContentSection {
                    __typename
                    ...DatoCmsCarouselPhotoContentSection
                }
                ... on DatoCmsHtmlSection {
                    __typename
                    ...DatoCmsHtmlSection
                }
                ... on DatoCmsTextSection {
                    __typename
                    ...DatoCmsTextSection
                }
                ... on DatoCmsTitleSection {
                    __typename
                    ...DatoCmsTitleSection
                }
                ... on DatoCmsSectionsTitleSection {
                    __typename
                    ...DatoCmsSectionsTitleSection
                }
                ... on DatoCmsPictureSection {
                    __typename
                    ...DatoCmsPictureSection
                }
                ... on DatoCmsItemsDescriptionSection {
                    __typename
                    ...DatoCmsItemsDescriptionSection
                }
                ... on DatoCmsCardsSection {
                    __typename
                    ...DatoCmsCardsSection
                }
                ... on DatoCmsAnchorSection {
                    __typename
                    ...DatoCmsAnchorSection
                }
                ... on DatoCmsCardsYearSection {
                    __typename
                    ...DatoCmsCardsYearSection
                }
            }
        }
    }
`;
