import { SectionsTitleSection } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';

export default SectionsTitleSection;

export const query = graphql`
    fragment DatoCmsSectionsTitleSection on DatoCmsSectionsTitleSection {
        title
        subtitle
    }
`;
