import React from 'react';
import { TextContentPagesSection as TextSection } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';

export default TextSection;

export const query = graphql`
    fragment DatoCmsTextSection on DatoCmsTextSection {
        title
        text
    }
`;
