import { graphql } from 'gatsby';
import React from 'react';
import { ItemsDescriptionSection as ItemsDescriptionDS } from '@solublestudio/cuerva-design-system';

export const CardsYearSection = (props) => {
    return <ItemsDescriptionDS {...props} reverse={true} />;
};

export const query = graphql`
    fragment DatoCmsCardYear on DatoCmsCardYear {
        title
        description
    }
    fragment DatoCmsCardsYearSection on DatoCmsCardsYearSection {
        title
        subtitle
        items {
            ... on DatoCmsCardYear {
                ...DatoCmsCardYear
            }
        }
    }
`;

export default CardsYearSection;
