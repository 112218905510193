import React from 'react';
import { HTMLSection } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';

export default HTMLSection;

export const query = graphql`
    fragment DatoCmsHtmlSection on DatoCmsHtmlSection {
        title
        text
    }
`;
