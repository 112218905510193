import { graphql } from 'gatsby';

import { ItemsDescriptionSection } from '@solublestudio/cuerva-design-system';

export default ItemsDescriptionSection;

export const query = graphql`
    fragment DatoCmsItemDescription on DatoCmsItemDescription {
        title
        image {
            alt
            file {
                publicURL
                childImageSharp {
                    gatsbyImageData(width: 1280)
                }
            }
        }
        description
    }
    fragment DatoCmsItemsDescriptionSection on DatoCmsItemsDescriptionSection {
        title
        subtitle
        featured
        items {
            ...DatoCmsItemDescription
        }
    }
`;
