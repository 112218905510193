import { PictureSection } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';

export default PictureSection;

export const query = graphql`
    fragment DatoCmsPictureSection on DatoCmsPictureSection {
        background {
            isImage
            alt
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                }
            }
        }

        size
    }
`;
