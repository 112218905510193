import React from 'react';
import { TitleContentPagesSection as TitleSection } from '@solublestudio/cuerva-design-system';
import { graphql } from 'gatsby';

export default TitleSection;

export const query = graphql`
    fragment DatoCmsTitleSection on DatoCmsTitleSection {
        title
    }
`;
