import React from 'react';
import { graphql } from 'gatsby';

import { CarouselCardsSection } from '@solublestudio/cuerva-design-system';

export default function CardsSection(props) {
    return <CarouselCardsSection {...props} />;
}

export const query = graphql`
    fragment DatoCmsCardMoreInfo on DatoCmsCardMoreInfo {
        title
        description
        internal {
            type
        }
    }
    fragment DatoCmsCardsSection on DatoCmsCardsSection {
        title
        subtitle
        items {
            ... on DatoCmsCardMoreInfo {
                ...DatoCmsCardMoreInfo
            }
        }
    }
`;
